import React from "react"

import Layout from "@src/components/layout"
import HomeSection from "@src/sections/home"
//import './i18n';
import Authwall from "../authwall";


const Home = (): React.ReactElement => {
  return (
    <Authwall>
      <Layout>
        <HomeSection />
      </Layout>
    </Authwall>
  )
}

export default Home
